export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "https://api.uat.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://api.rsbpct.com"
      : "http://127.0.0.1:5000",
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-380105.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod.appspot.com"
      : "gs://pct-dev-380105.appspot.com",

  surveillanceBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-surveillance"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat-surveillance"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod-surveillance"
      : "gs://pct-dev-surveillance"
};

export const logRocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/gzero-employee-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/gzero-employee-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/gzero-employee-prod"
      : "",
};

export function rippleEffect(event) {
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${
    event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius
  }px`;
  circle.style.top = `${
    event.clientY + containerElement.scrollTop - viewportOffset.y - radius
  }px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }) +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" });
    //   +
    //   " " +
    //   dateObject.toLocaleString("en-IN", { weekday: "long" }) +
    //   " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date = dateObject
      .toLocaleString("en-IN", {
        hour: "numeric" && "2-digit",
        minute: "numeric" && "2-digit",
        hour12: true,
      })
      .toUpperCase();

    // + " ";
    return date;
  }
}

export const locationRange = 150; //meters
export const movePerSecond = 5; //meters
export const liveLocationUpdateTime = 5; //seconds

export function calculateDistance(location1, location2) {
  const deg2rad = (deg) => deg * (Math.PI / 180);

  if (location1 && location2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = deg2rad(location2.latitude - location1.latitude);
    const dLon = deg2rad(location2.longitude - location1.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(location1.latitude)) *
        Math.cos(deg2rad(location2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Math.round(R * c * 1000); // Distance in meters

    return distance;
  } else {
    return null;
  }
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getPublicURL = (documentId) => {
  switch (process.env.REACT_APP_STAGING) {
    case "local":
      return `http://localhost:3002/${documentId}`;

    case "development":
      return `https://public.dev.gzero.byepo.com/${documentId}`;

    case "production":
      return `https://public.rsbpct.com/${documentId}`;

    case "UAT":
      return `https://public.uat.gzero.byepo.com/${documentId}`;

    default:
      return `https://public.dev.gzero.byepo.com/${documentId}`;
  }
};

export function base64ToBlob(base64, mimeType) {
  const binaryString = atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: mimeType });
}

function stripBase64Prefix(base64) {
  return base64.includes(",") ? base64.split(",")[1] : base64;
}

export function base64ToFile(base64, fileName, mimeType) {
  const data = stripBase64Prefix(base64);
  const blob = base64ToBlob(data, mimeType);
  return new File([blob], fileName, { type: mimeType });
}

export const complaintsCategory = [
  "Restroom is not open",
  "No water supply",
  "Lights not working",
  "Waterlogged in the drainage",
  "Broken doors, latches",
  "Lack of security measures",
  "Janitors are not on duty",
  "Unsanitary restroom",
  "Fees are charged",
  "Unhygienic environment",
];

export const complaintsQueryPath = {
  status: "status.currentStatus",
  issueRaised: "issuedBy.userDetails.phoneNumber",
  issueTakenByPhoneNumber: "takenOverBy.phoneNumber",
  issueTakenByEmail: "takenOverBy.email",
  category: "category",
  ward: "location.ward",
  zone: "location.zone",
  dateRange: "createdAt",
  PCTNo: "location.id",
};

export function isValidDate(dateString) {
  const regex = /^[1-9]\d{3}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}


export function base64ToImageElement(base64String) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64String;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
  });
}

export async function cropBase64Image(base64String, cropX, cropY, cropWidth, cropHeight) {
  // Convert Base64 to Image Element
  const img = await new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64String;
    image.onload = () => resolve(image);
    image.onerror = (err) => reject(err);
  });

  // Create a temporary canvas to draw the cropped image
  const canvas = document.createElement('canvas');
  canvas.width = cropWidth;
  canvas.height = cropHeight;
  const ctx = canvas.getContext('2d');

  // Draw the cropped area on the canvas
  ctx.drawImage(
    img,
    cropX, cropY, cropWidth, cropHeight, // Source crop area
    0, 0, cropWidth, cropHeight           // Destination on canvas
  );

  // Convert the canvas back to Base64
  return canvas.toDataURL('image/png');
}