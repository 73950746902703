import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { updateCapture } from "../../Services/database";

export const actionTypes = {
  UPDATE_CAPTURE_PICTURE: "UPDATE_CAPTURE_PICTURE"
};

function* updateCapturePictureWorker(action) {
  try {
    yield setFaceComparisonLoading(true);

    const data = {
      approval:{
        status: "PENDING",
        updatedAt: +new Date(),
      },
      name: action.payload.data.name,
      employee: action.payload.data.employee,
      updateReference: false
    }

    yield updateCapture(action.payload.data.capturedId, data);

    action.payload.navigate("/faceIdentification/uploadReference/success");

    yield setFaceComparisonLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setFaceComparisonLoading(false);
  }
}

export default function* faceComparisonWatcher() {
  yield all([
    takeEvery("UPDATE_CAPTURE_PICTURE", updateCapturePictureWorker)
  ]);
}

function* setFaceComparisonLoading(bool) {
  yield put({
    type: "SET_FACE_COMPARISON_LOADING",
    payload: {
      loading: bool
    }
  });
}
