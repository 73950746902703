import * as tf from "@tensorflow/tfjs";
import { setWasmPaths } from "@tensorflow/tfjs-backend-wasm";

import wasmSimdPath from "../../node_modules/@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-simd.wasm";
import wasmSimdThreadedPath from "../../node_modules/@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-threaded-simd.wasm";
import wasmPath from "../../node_modules/@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm.wasm";

import { isValidObject } from "./validators";
import { base64ToFile } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";
import { compareFace } from "./api";
import "@tensorflow/tfjs-backend-wasm";
import Human from "@vladmandic/human";
import { useState, useRef, useEffect } from 'react';



export function useFaceDetection() {
  const humanRef = useRef(null);
  const [humanInitialized, setHumanInitialized] = useState(false);

  async function initializeHuman(){
    if (humanRef.current){
      return;
    } 

    setWasmPaths({
      "tfjs-backend-wasm.wasm": wasmPath,
      "tfjs-backend-wasm-simd.wasm": wasmSimdPath,
      "tfjs-backend-wasm-threaded-simd.wasm": wasmSimdThreadedPath
    });

    await tf.setBackend('wasm');
    await tf.ready();

    const humanConfig = {
      backend: 'wasm',
      modelBasePath: `${process.env.PUBLIC_URL}/models`,
      face: { enabled: true },
      debug: false,
    };

    const instance = new Human(humanConfig);
    await instance.load();

    humanRef.current = instance;
    setHumanInitialized(true);
  }

  useEffect(() => {
    initializeHuman()
  }, [])

  
  async function detectFace(image) {
    if (!humanRef.current || !humanInitialized || !image) return null;
    let result = await humanRef.current.detect(image);
    if(result?.face?.length === 1){
      const box = result.face[0].box
      const isFullFaceVisible = box[0] > 0 && box[1] > 0 && box[0] + box[2] < result.width && box[1] + box[3] < result.height;
      console.log("isFullFaceVisible:", isFullFaceVisible);
      if(!isFullFaceVisible){
        result.face = []
      }
    }
    return result;
  };

  return {humanInitialized, detectFace};

}

export async function compareCapturedFace({
  photo,
  selectedLocation,
  profileData,
  accessToken
}) {
  try {
    if (isValidObject(selectedLocation)) {
      let data = new FormData();
      data.append("photo", base64ToFile(photo, "image.png", "image/png"));
      data.append("PCTNo", selectedLocation?.PCTNo);
      data.append("ward", selectedLocation?.ward);
      data.append("address", selectedLocation.location);
      data.append("zone", selectedLocation?.zone);
      data.append("locationId", selectedLocation.documentId);
      data.append("employeeName", profileData?.name || "N/A");
      data.append("employeeId", profileData?.employeeId);
      data.append("employeePhoneNumber", profileData?.phoneNumber);

      const response = await compareFace(data, accessToken);

      return response;
    } else {
      setErrorStatus({ code: "custom", message: "Not in valid location" });
    }
  } catch (error) {
    console.log("error from compareCapturedFace:", error);
    setErrorStatus(error);
  }
}
