import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import {
  base64ToImageElement,
  cropBase64Image,
  dateAndTimeConverter,
  locationRange,
  rippleEffect
} from "../../Utils/constants";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import {
  ArrowIcon,
  CameraFlipIcon,
  CloseIcon,
  FallbackClipArt,
  FrameCorner,
  NotInLocationPicture,
  SearchIcon
} from "../../Assets/assets";
import { Route, Routes } from "react-router-dom";
import {
  useFaceDetection,
  compareCapturedFace
} from "../../Services/faceDetection";

import defaultFace from "../../Assets/face/defaultFace.svg";
import pitchDown from "../../Assets/face/pitchDown.gif";
import pitchUp from "../../Assets/face/pitchUp.gif";
import yawLeft from "../../Assets/face/yawLeft.gif";
import yawRight from "../../Assets/face/yawRight.gif";
import rollLeft from "../../Assets/face/rollLeft.gif";
import rollRight from "../../Assets/face/rollRight.gif";
import { isValidArray } from "../../Services/validators";
import { search } from "fast-fuzzy";
import { getAllEmployees } from "../../Services/database";
import { updateCapturePicture } from "../../Redux/faceComparison/action";

function FaceIdentification(props) {
  const [facingMode, setFacingMode] = useState("user");
  const [faceComparisonData, setFaceComparisonData] = useState(null);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [capturedId, setCapturedId] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  useEffect(() => {
    if (
      props.locations.distance > locationRange &&
      window.location.pathname !== "/faceIdentification"
    ) {
      setFaceComparisonData(null);
      setCapturedPhoto(null);
      setSelectedEmployee(null);
      props.navigate("/faceIdentification");
    }

    // eslint-disable-next-line
  }, [props.locations.distance]);

  return (
    <div className="inherit-parent-height inherit-parent-width overflow-hidden">
      <Header
        title="Face Identification"
        profileOnClick={() => {
          props.navigate("/profile");
        }}
        backOnClick={() => {
          const locationPathName = window.location.pathname;
          if (locationPathName === "/faceIdentification") {
            props.navigate("/faceIdentification/locationSearch");
          } else if (
            locationPathName.includes("/faceIdentification/capturedImage")
          ) {
            props.navigate("/faceIdentification");
          } else if (
            locationPathName === "/faceIdentification/uploadReference"
          ) {
            setSelectedEmployee(null)
            props.navigate("/faceIdentification/employeeSearch");
          } else {
            props.navigate("/");
          }
        }}
      />

      <div className="remaining-body-height">
        {props.locations.distance > locationRange &&
          !props.locations.loading && (
            <div
              className="remaining-procedure-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
              data-cy="notInLocation-fallBack"
            >
              <NotInLocationPicture />
              <div className="padding-top-larger font-family-RHD-medium font-size-default">
                Not in location !
              </div>
              <div className="padding-top-medium text-align-center font-size-medium">
                Reach the assigned location to continue.
              </div>
            </div>
          )}
        {props.locations.distance < locationRange &&
          !props.locations.loading &&
          !faceComparisonData &&
          !capturedPhoto && (
            <CaptureImage
              setFacingMode={setFacingMode}
              facingMode={facingMode}
              navigate={props.navigate}
              compareCapturedFace={async (image) => {
                return await compareCapturedFace({
                  photo: image,
                  selectedLocation:
                    props.locations.data?.[props.locations.selectedLocation],
                  profileData: props.profile.data,
                  accessToken: props.auth.data.accessToken
                });
              }}
              setFaceComparisonData={setFaceComparisonData}
              faceComparison={props.faceComparison}
              setCapturedPhoto={setCapturedPhoto}
              selectedEmployee={selectedEmployee}
              setCapturedId={setCapturedId}
            />
          )}

        <Routes>
          <Route
            path="/success"
            element={
              <ComparisonSuccess
                continueOnClick={() => {
                  props.navigate("/");
                }}
                location={
                  props.locations.selectedLocation &&
                  props.locations.data?.[props.locations.selectedLocation]
                }
                faceComparisonData={faceComparisonData}
                setFaceComparisonData={setFaceComparisonData}
                buttonText="Okay"
                navigate={props.navigate}
              />
            }
          />
          <Route
            path="/failed"
            element={
              <ComparisonFailed
                buttonOnClick={() => {
                  props.navigate("/faceIdentification");
                  setCapturedPhoto(null);
                }}
                location={
                  props.locations.selectedLocation &&
                  props.locations.data?.[props.locations.selectedLocation]
                }
                buttonText="Retake"
                capturedPhoto={capturedPhoto}
                setCapturedPhoto={setCapturedPhoto}
                setCapturedId={setCapturedId}
                navigate={props.navigate}
              />
            }
          />
          <Route
            path="/employeeSearch"
            element={
              <EmployeeSearch
                capturedPhoto={capturedPhoto}
                setSelectedEmployee={setSelectedEmployee}
                navigate={props.navigate}
              />
            }
          />
          <Route
            path="/uploadReference"
            element={
              <UploadReference
                selectedEmployee={selectedEmployee}
                capturedPhoto={capturedPhoto}
                navigate={props.navigate}
                loading={props.faceComparison.loading}
                confirmOnClick={() => {
                  props.updateCapturePicture(
                    {
                      name: selectedEmployee.name,
                      employee: {
                        id: selectedEmployee.documentId,
                        name: selectedEmployee.name,
                        phoneNumber: selectedEmployee.phoneNumber,
                        roles: selectedEmployee.roles
                      },
                      capturedId: capturedId,
                    },
                    props.navigate
                  );
                }}
                retakeOnClick={() => {
                  setCapturedPhoto(null);
                }}
                capturedId={capturedId}
              />
            }
          />
          <Route
            path="/uploadReference/success"
            element={
              <UploadReferenceSuccess
                selectedEmployee={selectedEmployee}
                capturedPhoto={capturedPhoto}
                navigate={props.navigate}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    faceComparison: state.faceComparison,
    locations: state.locations,
    profile: state.profile,
    auth: state.auth
  };
};

const mapDispatchToProps = function () {
  return {
    updateCapturePicture: (data, navigate) =>
      updateCapturePicture(data, navigate)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceIdentification);

const CaptureImage = (props) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const detectionLoading = useRef(false);
  const [detectionError, setDetectionError] = useState({
    status: null,
    message: "Please capture the subject's face for comparison",
    gif: defaultFace
  });

  const { humanInitialized, detectFace } = useFaceDetection();

  const startCamera = async () => {
    const facingMode = props.facingMode;
    try {
      const constraints = {
        video: { facingMode },
        audio: false
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert("Could not access the camera. Please allow camera permissions.");
    }
  };

  const checkIsValidPhoto = async (detectedData) => {
    console.log({
      angle: detectedData?.face?.[0]?.rotation?.angle,
      distance: detectedData?.face?.[0]?.distance
    });
    if (detectedData?.face?.length !== 1) {
      setDetectionError({
        status: null,
        message: "Please capture the subject's face for comparison",
        gif: defaultFace
      });
      return false;
    } else if (detectedData?.face[0]?.distance > 0.5) {
      setDetectionError({
        status: "tooFar",
        message: "Face detected too far. Move closer to the focus area",
        gif: defaultFace
      });
      return false;
    } else if (detectedData?.face[0]?.distance < 0.25) {
      setDetectionError({
        status: "tooClose",
        message: "Face detected too close. Move back to the focus area",
        gif: defaultFace
      });
      return false;
    }

    const sortedAngles = Object.entries(
      detectedData.face[0].rotation.angle
    ).sort((a, b) => {
      return Math.abs(b[1]) - Math.abs(a[1]);
    });

    for (const angle of sortedAngles) {
      switch (angle[0]) {
        case "pitch":
          if (angle[1] < -0.1) {
            setDetectionError({
              status: "pitchUp",
              message: "Face too high. Please lower your head",
              gif: pitchUp
            });
            return false;
          } else if (angle[1] > 0.1) {
            setDetectionError({
              status: "pitchDown",
              message: "Face too low. Please raise your head",
              gif: pitchDown
            });
            return false;
          }
          break;

        case "yaw":
          if (angle[1] < -0.1) {
            setDetectionError({
              status: "yawLeft",
              message: "Face turned to left. Please straighten your head",
              gif: props.facingMode === "user" ? yawLeft : yawRight
            });
            return false;
          } else if (angle[1] > 0.1) {
            setDetectionError({
              status: "yawRight",
              message: "Face turned to right. Please straighten your head",
              gif: props.facingMode === "user" ? yawRight : yawLeft
            });
            return false;
          }
          break;

        case "roll":
          if (angle[1] < -0.1) {
            setDetectionError({
              status: "rollRight",
              message: "Face tilted to right. Please straighten your head",
              gif: props.facingMode === "user" ? rollRight : rollLeft
            });
            return false;
          } else if (angle[1] > 0.1) {
            setDetectionError({
              status: "rollLeft",
              message: "Face tilted to left. Please straighten your head",
              gif: props.facingMode === "user" ? rollLeft : rollRight
            });
            return false;
          }
          break;

        default:
          break;
      }
    }

    if (
      detectedData.face[0].emotion.find((data) => data.emotion === "neutral")
        ?.score < 0.6
    ) {
      setDetectionError({
        status: "emotion",
        message: "Please maintain a neutral expression",
        gif: defaultFace
      });
      return false;
    }

    setDetectionError({
      status: "good",
      message: "Hang on... Verifying face",
      gif: defaultFace
    });
    return true;
  };

  const captureImage = async () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = canvas.toDataURL("image/png");

      const cropX = canvas.width / 2 - 300 / 2; //(photoWidth / 2) - (cropWidth / 2)
      const cropY = canvas.height / 3 - 300 / 2; //(photoHeight / 3) - (cropHeight / 2)

      const croppedImage = await cropBase64Image(
        imageData,
        cropX,
        cropY,
        300,
        300
      );
      const imageFile = await base64ToImageElement(croppedImage);
      const detectedData = await detectFace(imageFile);
      const isValidPhoto = await checkIsValidPhoto(detectedData);

      console.log("isValidPhoto:", isValidPhoto);

      if (isValidPhoto !== true) {
        detectionLoading.current = false;
        return;
      }

      if (props.selectedEmployee) {
        props.setCapturedPhoto(croppedImage);
        props.navigate("/faceIdentification/uploadReference");
        return;
      }

      const result = await props.compareCapturedFace(croppedImage);

      if (result?.success === true) {
        props.setFaceComparisonData(result.data[0]);
        props.navigate("/faceIdentification/success");
      } else if (result?.success === false) {
        props.setCapturedPhoto(croppedImage);
        props.setCapturedId(result.capturedId);
        props.navigate("/faceIdentification/failed");
      } else {
        props.navigate("/");
      }
      // const tracks = video.srcObject.getTracks();
      // tracks.forEach((track) => track.stop());
    } else {
      console.error("Camera is not initialized properly.");
    }
    detectionLoading.current = false;
  };

  useEffect(() => {
    let interval;
    if (humanInitialized) {
      interval = setInterval(async () => {
        try {
          if (detectionLoading.current === false) {
            detectionLoading.current = true;
            console.log("call capture");
            await captureImage();
          }
        } catch (error) {
          detectionLoading.current = false;
          console.error("error from faceDetection", error)
        }
      }, 1000);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [humanInitialized]);

  useEffect(() => {
    if (!humanInitialized) {
      return;
    }

    startCamera();
    const currentVideoRef = videoRef.current;

    return () => {
      if (currentVideoRef?.srcObject) {
        const tracks = currentVideoRef.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line
  }, [props.facingMode, humanInitialized]);

  const switchCamera = async () => {
    props.setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };
  return (
    <div className="inherit-parent-height inherit-parent-width position-relative">
      {props.faceComparison.loading && (
        <div className="display-flex flex-justify-content-center flex-align-items-center">
          <div
            style={{
              width: "100%",
              height: "100%"
            }}
            className="face-loading-overlay"
          >
            <div style={{ height: "32px", width: "32px" }}>
              <div className="primary-loading-animation" />
            </div>
          </div>
        </div>
      )}
      <>
        <video
          ref={videoRef}
          playsInline
          autoPlay
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            ...(props.facingMode === "user"
              ? {
                  transform: "scaleX(-1)",
                  WebkitTransform: "scaleX(-1)"
                }
              : {})
          }}
        />
        <canvas ref={canvasRef} style={{ display: "none" }} />

        {/* Capture Mark Box */}
        <div
          style={{
            position: "absolute",
            border: `2px solid ${
              detectionError.status === "good" ? "#2965dd" : "#E2E2E2"
            }`,
            top: "30%",
            left: "50%",
            width: "260px",
            height: "280px",
            boxSizing: "border-box",
            transform: "translate(-50%, -50%)",
            zIndex: 3
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            width: "300px",
            height: "300px",
            transform: "translate(-50%, -50%)",
            zIndex: 4
          }}
        >
          <FrameCorner
            color={detectionError.status === "good" ? "#2965dd" : "#E2E2E2"}
            width={300}
            height={300}
          />
        </div>

        <div
          className=" padding-large display-flex flex-direction-column flex-align-items-center"
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            zIndex: 10,
            clipPath:
              "polygon(0% 0%, 0% 100%, 10% 100%, 10% 8%, 90% 8%, 90% 52%, 10% 52%, 10% 100%, 100% 100%, 100% 0%)"
          }}
        ></div>
        <div
          className=" padding-large display-flex flex-direction-column flex-align-items-center"
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "45%",
            backgroundImage:
              "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%)",
            paddingTop: "70px",
            zIndex: 10
          }}
        >
          <img
            src={detectionError.gif}
            alt="face detecting"
            style={{
              height: "80px"
              // width: "48px"
            }}
          />

          <div
            className={`" padding-top-large font-size-small text-align-center ${
              detectionError.status === null || detectionError.status === "good"
                ? ""
                : "font-color-red"
            }  `}
          >
            {detectionError.message}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            height: "64px",
            bottom: "0",
            zIndex: 20,
            borderTop: "solid 1px #E2E2E2"
          }}
          className="display-flex flex-justify-content-center flex-align-items-center inherit-parent-width "
          onClick={(event) => {
            rippleEffect(event);
            switchCamera();
          }}
        >
          <CameraFlipIcon />

          <div className=" padding-left-default font-size-medium">
            Flip camera
          </div>
        </div>
      </>
    </div>
  );
};

const ComparisonSuccess = (props) => {
  useEffect(() => {
    if (props.faceComparisonData === null) {
      props.navigate("/");
    }

    return () => {
      props.setFaceComparisonData(null);
    };
    // eslint-disable-next-line
  }, [props.faceComparisonData]);
  return (
    <div className="inherit-parent-height inherit-parent-width padding-larger display-flex flex-direction-column flex-justify-content-space-between">
      <div
        style={{ height: "calc(100% - 68px)" }}
        className=" display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      >
        <div className="font-size-medium font-family-RHD-medium text-align-center ">
          PROFILE MATCHED SUCCESSFULLY
        </div>
        <div className="font-size-small text-align-center padding-top-small ">
          Your attendance for this interval is registered
        </div>

        <div className="wrapper padding-top-larger">
          <svg className="checkmark" viewBox="0 0 52 52">
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>

        <div className=" padding-top-larger display-flex ">
          <div className=" font-size-small padding-right-small">
            CAPTURED IMAGE
            <div
              style={{
                width: "156px",
                height: "182px"
              }}
            >
              <img
                style={{
                  objectFit: "cover"
                }}
                className="inherit-parent-height inherit-parent-width"
                src={`data:image/png;base64,${props.faceComparisonData?.captured?.photo}`}
                alt="capturedImage"
              />
            </div>
          </div>

          <div className=" font-size-small padding-left-small">
            MATCHED IMAGE
            <div
              style={{
                width: "156px",
                height: "182px"
              }}
            >
              <img
                style={{
                  objectFit: "cover"
                }}
                className="inherit-parent-height inherit-parent-width"
                src={`data:image/png;base64,${props.faceComparisonData?.matched?.photo}`}
                alt="capturedImage"
              />
            </div>
          </div>
        </div>

        <div className=" padding-top-larger text-align-center font-size-medium font-family-RHD-medium">
          {props.faceComparisonData?.matched?.employee?.name || "N/A"}
        </div>

        <div className=" padding-top-default text-align-center font-size-small">
          Captured in
          <span className=" font-family-RHD-medium">{` ${props.location?.PCTNo} - ${props.location.location}`}</span>{" "}
          on{" "}
          {`${dateAndTimeConverter(
            props.faceComparisonData?.capturedAt,
            "secondDate"
          )}`}
          ,
          <span className=" font-family-RHD-medium">{` ${dateAndTimeConverter(
            props.faceComparisonData?.capturedAt,
            "Time"
          )} `}</span>
        </div>
      </div>

      <Button
        data-cy={"continue-to-home-button"}
        type="button"
        text={props.buttonText}
        boxShadow={false}
        onClick={() => {
          props.continueOnClick();
        }}
      />
    </div>
  );
};

const ComparisonFailed = (props) => {
  useEffect(() => {
    if (props.capturedPhoto === null) {
      props.navigate("/");
    }
    // eslint-disable-next-line
  }, [props.capturedPhoto]);

  return (
    <div className="inherit-parent-height inherit-parent-width padding-larger display-flex flex-direction-column flex-justify-content-space-between">
      <div
        style={{ height: "calc(100% - 68px)" }}
        className=" display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      >
        <div className="font-size-medium font-family-RHD-medium text-align-center ">
          NO MATCHES FOUND
        </div>
        <div className="font-size-small text-align-center padding-top-small ">
          No reference user found for the captured face
        </div>

        <div className=" padding-top-larger">
          <div
            style={{
              width: "156px",
              height: "182px",
              border: "solid 2px red",
              borderRadius: "4px"
            }}
          >
            <img
              style={{
                objectFit: "cover"
              }}
              className="inherit-parent-height inherit-parent-width"
              src={props.capturedPhoto}
              alt="capturedImage"
            />
          </div>
        </div>

        <div className=" padding-top-larger text-align-center font-size-small">
          Captured in
          <span className=" font-family-RHD-medium">{` ${props.location?.PCTNo} - ${props.location.location}`}</span>{" "}
          on {`${dateAndTimeConverter(+new Date(), "secondDate")}`},
          <span className=" font-family-RHD-medium">{` ${dateAndTimeConverter(
            +new Date(),
            "Time"
          )} `}</span>
        </div>
      </div>

      <div className=" font-size-small text-align-center">
        <span
          className=" font-family-RHD-medium font-color-primary"
          onClick={() => {
            props.navigate("/faceIdentification/employeeSearch");
          }}
        >
          Submit{" "}
        </span>
         for manual approval
      </div>
      <div className=" padding-vertical-medium display-flex flex-justify-content-center flex-align-items-center">
        <div
          style={{
            width: "100%",
            border: "1px solid #F2F2F2"
          }}
        />
        <div className="font-size-small padding-horizontal-default">OR</div>
        <div
          style={{
            width: "100%",
            border: "1px solid #F2F2F2"
          }}
        />
      </div>
      <Button
        data-cy={` ${props.buttonText}-button"`}
        type="button"
        text={props.buttonText}
        boxShadow={false}
        onClick={() => {
          props.buttonOnClick();
        }}
      />
    </div>
  );
};

const EmployeeSearch = (props) => {
  const [searchKey, setSearchKey] = useState("");
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    if (props.capturedPhoto === null) {
      props.navigate("/");
    }

    const fetchEmployees = async () => {
      const data = await getAllEmployees();
      setEmployees(data);
    };

    fetchEmployees();
    // eslint-disable-next-line
  }, []);

  const results = useMemo(() => {
    if (isValidArray(employees)) {
      if (!searchKey) {
        return employees;
      }
      return search(searchKey, employees, {
        keySelector: (item) => `${item.phoneNumber} ${item.name}`,
        threshold: 0.7
      });
    }
  }, [searchKey, employees]);

  return (
    <div className="inherit-parent-height inherit-parent-width overflow-hidden">
      <div className="display-flex padding-top-large padding-left-large padding-right-large flex-direction-column">
        <div className="font-family-RHD-medium ">Capture Reference Image</div>
        <div className=" font-size-small padding-bottom-default">
          Search and capture an employee’s reference photo
        </div>
      </div>
      <>
        <div className="display-flex flex-justify-content-center flex-align-items-center border-bottom-1px margin-right-large margin-left-large margin-top-default margin-bottom-large">
          <input
            style={{
              width: "100%"
            }}
            className={`inherit-parent-height border-none font-family-RHD-regular font-size-large padding-top-default padding-top-default padding-bottom-default padding-right-default inherit-parent-width`}
            value={searchKey}
            autoFocus={true}
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
            placeholder="Search"
            title="Search bar"
            data-cy="search-input-field"
          />
          <button
            className="padding-right-default background-color-transparent"
            type={"button"}
            data-cy={
              searchKey?.length > 0 ? "search-clear-button" : "search-button"
            }
            onClick={() => {
              if (searchKey?.length > 0) {
                setSearchKey("");
              }
            }}
          >
            {searchKey?.length < 1 ? (
              <SearchIcon />
            ) : (
              <CloseIcon width={20} height={20} />
            )}
          </button>
        </div>

        <div className=" inherit-parent-width remaining-body-height_-229px flex-justify-content-center ">
          <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar flex-justify-content-center">
            {isValidArray(employees) && isValidArray(results) && (
              <div className="inherit-parent-width inherit-parent-height">
                <div
                  className="padding-left-large padding-right-large padding-bottom-large inherit-parent-width inherit-parent-height"
                  data-cy="locations-list"
                >
                  {results.map((data, index) => (
                    <div className="inherit-parent-width" key={index}>
                      <Card
                        onClick={() => {
                          props.setSelectedEmployee(data);
                          props.navigate("/faceIdentification/uploadReference");
                        }}
                        index={index}
                        data={data}
                        loading={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {employees !== null &&
              (!isValidArray(employees) || !isValidArray(results)) && (
                <NoEmployeesFallback />
              )}

            {employees === null && <Suspense />}
          </div>
        </div>
      </>
    </div>
  );
};

const UploadReference = (props) => {
  useEffect(() => {
    if (props.capturedPhoto === null || props.selectedEmployee === null) {
      props.navigate("/");
    }
    // eslint-disable-next-line
  }, [props.capturedPhoto, props.selectedEmployee]);

  return (
    <div className="inherit-parent-height inherit-parent-width padding-larger display-flex flex-direction-column flex-justify-content-space-between">
      <div
        style={{ height: "calc(100% - 68px)" }}
        className=" display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      >
        <div className="font-size-medium font-family-RHD-medium text-align-center ">
          CONFIRM MANUAL APPROVAL
        </div>
        <div className="font-size-small text-align-center padding-top-small ">
          Please verify the employee details before submission
        </div>

        <div className=" padding-top-larger">
          <div
            style={{
              width: "156px",
              height: "182px",
              border: "solid 2px #2965DD",
              borderRadius: "4px"
            }}
          >
            <img
              style={{
                objectFit: "cover"
              }}
              className="inherit-parent-height inherit-parent-width"
              src={props.capturedPhoto}
              alt="capturedImage"
            />
          </div>
        </div>

        <div className=" padding-top-larger text-align-center font-size-medium">
          <span className=" font-family-RHD-medium"> {props.selectedEmployee.phoneNumber} </span>, {props.selectedEmployee.name}
        </div>
      </div>

      <div className=" display-flex flex-justify-content-space-between">
        <Button
          data-cy={`confirm-button"`}
          className="margin-right-small"
          type="button"
          text={"Confirm"}
          boxShadow={false}
          loading={props.loading}
          onClick={() => {
            props.confirmOnClick();
          }}
        />
        <Button
          data-cy={`retake-button"`}
          className="margin-left-small"
          variant="secondary"
          type="button"
          text={"Retake"}
          boxShadow={false}
          loading={props.loading}
          onClick={() => {
            props.retakeOnClick();
          }}
        />
      </div>
    </div>
  );
};

const UploadReferenceSuccess = (props) => {
  useEffect(() => {
    if (props.capturedPhoto === null || props.selectedEmployee === null) {
      props.navigate("/");
    }
    // eslint-disable-next-line
  }, [props.capturedPhoto, props.selectedEmployee]);

  return (
    <div className="inherit-parent-height inherit-parent-width padding-larger display-flex flex-direction-column flex-justify-content-space-between">
      <div
        style={{ height: "calc(100% - 68px)" }}
        className=" display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
      >
        <div className="font-size-medium font-family-RHD-medium text-align-center ">
          Submitted for approval
        </div>

        <div className="wrapper padding-top-larger">
          <svg className="checkmark" viewBox="0 0 52 52">
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>

        <div className=" padding-top-larger text-align-center font-size-medium font-family-RHD-medium">
          {`Employee name: ${props.selectedEmployee?.name || "N/A"}`} <br />
          {props.selectedEmployee?.phoneNumber || "N/A"}
        </div>

        <div className=" padding-top-default text-align-center font-size-small">
          Attendance will be provided once approved
        </div>
      </div>

      <Button
        data-cy={"okay-button"}
        type="button"
        text={"Okay"}
        boxShadow={false}
        onClick={() => {
          props.navigate("/");
        }}
      />
    </div>
  );
};

const Card = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="display-flex inherit-parent-width padding-bottom-large"
      data-cy={`location-card-${props.data.documentId}`}
    >
      <div className="inherit-parent-width display-flex flex-justify-content-space-between">
        <div className=" parent-width_-68px">
          {!props.loading ? (
            <>
              <div className=" font-size-medium font-family-RHD-medium padding-bottom-small inherit-parent-width text-overflow-ellipsis">
                {props.data.phoneNumber}
              </div>
              <div className="font-size-small">{props.data.name}</div>
            </>
          ) : (
            <div className="inherit-parent-width">
              <div className="shimmer padding-large border-radius-default inherit-parent-width" />
            </div>
          )}
        </div>
        <div className=" display-flex flex-align-items-center ">
          <div className=" display-flex flex-align-items-center ">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const NoEmployeesFallback = () => {
  return (
    <div
      className="inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-locations-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        Employees are not Available
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after sometime.
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <div className=" inherit-parent-width padding-top-large">
      {[...Array(7)].map((_data, index) => (
        <div className=" padding-default inherit-parent-width" key={index}>
          <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
        </div>
      ))}
    </div>
  );
};
