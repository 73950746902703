import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./selectedProcedure.css";
import Header from "../../Components/Header/Header";
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  NotInLocationPicture,
  ProcedureTitleIcon,
  WaitingFallBackIcon
} from "../../Assets/assets";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  dateAndTimeConverter,
  getPublicURL,
  locationRange,
  openInNewTab
} from "../../Utils/constants";
import {
  completeProcedure,
  getProceduresHistory,
  setSelectedProcedure
} from "../../Redux/procedures/action";
import TrackProgress from "../../Components/TrackProgress/TrackProgress";
import Button from "../../Components/Button/Button";
import FileUploadModal from "../../Components/FileUploadModal/FileUploadModal";
import Modal from "../../Components/Modal/Modal";
import CropperModal from "../../Components/CropperModal/CropperModal";

function SelectedProcedure(props) {
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [file, setFile] = useState({
    image: [],
    audio: null
  });
  const [stepsStatus, setStepsStatus] = useState(null);
  const [selectedStepStatus, setSelectedStepStatus] = useState(null);
  const [clearAudio, setClearAudio] = useState(false);
  const [proof, setProof] = useState({
    required: null,
    status: null
  });

  useEffect(() => {
    let steps = [];
    const selectedProcedure = props.procedures?.selectedProcedure;
    if (selectedProcedure?.procedureId) {
      props.procedures?.data?.[selectedProcedure?.procedureId]?.steps?.forEach(
        (data) => {
          steps?.push({ ...data, status: false, success: null, proof: null });
        }
      );
      setStepsStatus(steps);
    }
    // eslint-disable-next-line
  }, [props.procedures]);

  useEffect(() => {
    const selectedProcedure = props.procedures?.selectedProcedure;
    if (selectedProcedure?.procedureId && selectedProcedure.locationId) {
      props.getProceduresHistory(
        selectedProcedure?.procedureId,
        selectedProcedure.locationId
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleDisable = () => {
    if (isValidArray(selectedStepStatus?.croppedImage)) {
      return false;
    } else {
      return true;
    }
  };

  const checkProcedureStatus = () => {
    let _upcomingProcedure = [];

    const selectedProcedure = props.procedures?.selectedProcedure;
    if (isValidObject(selectedProcedure)) {
      let procedureHistory =
        props.procedures.proceduresHistory &&
        props.procedures.proceduresHistory?.[selectedProcedure?.locationId] &&
        Object.values(
          props.procedures.proceduresHistory?.[selectedProcedure?.locationId]
        )?.filter(
          (historyData) =>
            historyData.procedureId === selectedProcedure.procedureId
        );

      const startingTime = new Date().setHours(
        parseInt(selectedProcedure.from?.split(":")[0]),
        parseInt(selectedProcedure.from?.split(":")[1]),
        0,
        0
      );

      const endingTime = new Date().setHours(
        parseInt(selectedProcedure.to?.split(":")[0]),
        parseInt(selectedProcedure.to?.split(":")[1]),
        0,
        0
      );

      const interval = getValidTimingData(
        selectedProcedure.period,
        selectedProcedure.interval
      );

      if (interval) {
        const timestamps = getTimestampsBetween(
          startingTime,
          endingTime,
          interval
        );
        if (isValidArray(procedureHistory)) {
          const sortedProcedureHistory = procedureHistory.sort(
            (first, second) => {
              return second.createdAt - first.createdAt;
            }
          );

          if (
            timestamps.find(
              (data) =>
                data > sortedProcedureHistory[0].createdAt &&
                data <= +new Date() &&
                timestamps[timestamps.length - 1] >= +new Date()
            )
          ) {
            _upcomingProcedure.push(selectedProcedure);
          }
        } else {
          if (
            timestamps.find(
              (data) =>
                data <= +new Date() &&
                timestamps[timestamps.length - 1] >= +new Date()
            ) &&
            selectedProcedure.period === "hours"
          ) {
            _upcomingProcedure.push(selectedProcedure);
          } else {
            if (timestamps.find((data) => data <= +new Date())) {
              _upcomingProcedure.push(selectedProcedure);
            }
          }
        }

        if (_upcomingProcedure.includes(selectedProcedure)) {
          return {
            status: true,
            timestamp: timestamps.find((data) => data >= +new Date())
          };
        } else {
          return {
            status: false,
            timestamp:
              timestamps.find((data) => data >= +new Date()) || timestamps[0]
          };
        }
      }
    }
  };

  useEffect(() => {
    if (!props.procedures?.selectedProcedure) {
      props.navigate(-1);
    }
    // eslint-disable-next-line
  }, [props.procedures?.selectedProcedure]);

  return (
    <div className="inherit-parent-height inherit-parent-width">
      <Header
        title="Procedure"
        backOnClick={() => {
          props.navigate(-1);
          props.setSelectedProcedure(null);
        }}
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />
      {props.procedures?.selectedProcedure !== null && (
        <>
          <div className="remaining-body-height display-flex flex-justify-content-center overflow-hidden">
            <div className="inherit-parent-height inherit-parent-width max-width-767px hide-scrollbar">
              <div className="inherit-parent-width">
                <div className="padding-large inherit-parent-width">
                  <div className="font-family-RHD-medium">
                    Follow the procedures
                  </div>
                  <div className="font-size-small">
                    Complete the assigned procedures by following the steps
                  </div>

                  <div className="padding-top-large display-flex flex-align-items-center">
                    <ClockIcon />
                    <div className="padding-left-large font-size-small">
                      {props.procedures?.selectedProcedure?.interval}{" "}
                      {props.procedures?.selectedProcedure?.period}
                    </div>
                  </div>

                  <div className="padding-top-large display-flex flex-align-items-center">
                    <CalendarIcon />
                    <div className="padding-left-large font-size-small">
                      {dateAndTimeConverter(+new Date(), "cardDate")}
                    </div>
                  </div>

                  <div className="padding-top-large display-flex flex-align-items-center">
                    <ProcedureTitleIcon />
                    <div className="padding-left-large font-size-small">
                      {props.procedures?.selectedProcedure?.procedureId &&
                        props.procedures.data?.[
                          props.procedures.selectedProcedure?.procedureId
                        ]?.title}
                    </div>
                  </div>

                  <div className="padding-top-large display-flex flex-align-items-center">
                    <LocationIcon />
                    <div className="padding-left-large font-size-small">
                      {`${props.locations?.data?.[
                        props.procedures.selectedProcedure?.locationId
                      ]?.PCTNo?.toString().padStart(2, "0")}
                        - ${
                          props.locations?.data?.[
                            props.procedures.selectedProcedure?.locationId
                          ]?.location
                        }`}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="background-color-secondary" />
              {isValidArray(stepsStatus) &&
                checkProcedureStatus()?.status === true &&
                props.locations.distance !== null &&
                props.locations.distance <= locationRange && (
                  <div
                    style={{
                      height: "calc(100% - 199px)"
                    }}
                    className="padding-large display-flex flex-direction-column"
                    data-cy="task-area"
                  >
                    <div className="font-family-RHD-medium padding-bottom-large">
                      Task List
                    </div>
                    <div
                      style={{ height: "calc(100% - 44px)" }}
                      className="overflow-y-scroll hide-scrollbar"
                      data-cy="task-list"
                    >
                      <TrackProgress
                        data={stepsStatus}
                        loading={props.procedures.loading}
                        setSelectedTaskIndex={(
                          index,
                          success,
                          isProofRequired
                        ) => {
                          setProof({
                            required: isProofRequired,
                            status: success
                          });
                          if (isProofRequired) {
                            setSelectedTaskIndex(index);
                          } else {
                            stepsStatus[index] = {
                              ...stepsStatus[index],
                              status: true,
                              success: true,
                              ...(stepsStatus[index]?.kpi
                                ? { selectedKpi: stepsStatus[index]?.kpi }
                                : {})
                            };
                          }
                          setSelectedStepStatus({
                            ...selectedStepStatus,
                            success: success
                          });
                        }}
                      />
                    </div>

                    <Button
                      text="Procedure Finished"
                      variant="primary"
                      boxShadow={false}
                      type="button"
                      className="margin-top-large"
                      data-cy="procedure-finished-button"
                      loading={props.procedures.loading}
                      disabled={stepsStatus?.some(
                        (data) => data.success === null
                      )}
                      onClick={() => {
                        props.completeProcedure(stepsStatus);
                      }}
                    />

                    <FileUploadModal
                      showModal={typeof selectedTaskIndex === "number"}
                      proof={proof}
                      type="procedure"
                      kpi={
                        props.procedures.data?.[
                          props.procedures.selectedProcedure?.procedureId
                        ]?.title === "Key performance indicators"
                      }
                      selectedTask={stepsStatus[selectedTaskIndex]}
                      selectedTaskIndex={selectedTaskIndex}
                      closeModal={() => {
                        setSelectedTaskIndex(null);
                      }}
                      isUpdate={false}
                      setFile={setFile}
                      file={file}
                      RemoveImageOnClick={(data) => {
                        stepsStatus[selectedTaskIndex] = {
                          ...stepsStatus[selectedTaskIndex],
                          ...(selectedStepStatus.croppedImage || file.audio
                            ? {
                                proof: stepsStatus[
                                  selectedTaskIndex
                                ]?.proof?.filter(
                                  (proof) => proof?.name !== data
                                )
                              }
                            : { proof: [] })
                        };
                        setSelectedStepStatus({
                          ...selectedStepStatus,
                          croppedImage:
                            selectedStepStatus?.croppedImage?.filter(
                              (value) => value.name !== data
                            )
                        });
                      }}
                      tempSrc={selectedStepStatus?.croppedImage}
                      disabled={handleDisable()}
                      setClearAudio={setClearAudio}
                      clearAudio={clearAudio}
                      continueOnClick={(data) => {
                        stepsStatus[selectedTaskIndex] = {
                          ...stepsStatus[selectedTaskIndex],
                          ...data,
                          ...(data.remarks ? { remarks: data.remarks } : {}),
                          ...(selectedStepStatus.croppedImage || file.audio
                            ? {
                                proof: [
                                  ...(stepsStatus[selectedTaskIndex]?.proof ||
                                    []),
                                  ...(selectedStepStatus.croppedImage || []),
                                  file.audio
                                ]
                              }
                            : { proof: [] }),
                          status: true,
                          success: selectedStepStatus.success
                        };
                        setSelectedTaskIndex(null);
                        setSelectedStepStatus(null);
                        setFile({
                          image: [],
                          audio: []
                        });
                        setClearAudio(true);
                      }}
                    />

                    <Modal
                      show={isValidArray(file?.image)}
                      canIgnore={true}
                      onClose={() => {
                        setFile({ ...file, image: [] });
                      }}
                      width="inherit-parent-width"
                      maxWidth="max-width-800px"
                      background="false"
                      boxShadow="false"
                      borderRadius="false"
                      height="inherit-parent-height"
                    >
                      <div
                        data-cy="cropper-modal"
                        className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
                      >
                        <CropperModal
                          className="border-radius-default"
                          OnBlobCreate={(blob) => {
                            const imageFile = new File(
                              [blob],
                              `${+new Date()}.png`,
                              {
                                type: "image/png"
                              }
                            );
                            setSelectedStepStatus({
                              ...selectedStepStatus,
                              ...(selectedStepStatus.croppedImage &&
                              isValidArray(selectedStepStatus.croppedImage)
                                ? {
                                    croppedImage: [
                                      ...selectedStepStatus.croppedImage,
                                      imageFile
                                    ]
                                  }
                                : { croppedImage: [imageFile] })
                            });
                          }}
                          file={file.image}
                          setFile={() => {
                            setFile({ ...file, image: [] });
                          }}
                        />
                      </div>
                    </Modal>
                  </div>
                )}

              {!checkProcedureStatus()?.status && (
                <div
                  className="remaining-procedure-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
                  data-cy="notInLocation-fallBack"
                >
                  <WaitingFallBackIcon />
                  <div className="padding-top-larger font-family-RHD-medium font-size-default">
                    Procedure Interval
                  </div>
                  <div className="padding-top-medium text-align-center font-size-medium">
                    The next procedure will be available on{" "}
                    {dateAndTimeConverter(
                      checkProcedureStatus()?.timestamp,
                      "Time"
                    )}{" "}
                    ,
                    {dateAndTimeConverter(
                      checkProcedureStatus()?.timestamp,
                      "cardDate"
                    )}
                  </div>
                </div>
              )}

              {checkProcedureStatus()?.status === true &&
                (props.locations.distance === null ||
                  props.locations?.distance > locationRange) && (
                  <div
                    className="remaining-procedure-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
                    data-cy="notInLocation-fallBack"
                  >
                    <NotInLocationPicture />
                    <div className="padding-top-larger font-family-RHD-medium font-size-default">
                      Not in location ?
                    </div>
                    <div className="padding-top-medium text-align-center font-size-medium">
                      Reach the assigned location to continue the procedure.
                    </div>
                    <div
                      onClick={() => {
                        openInNewTab(
                          getPublicURL(
                            props.procedures.selectedProcedure?.locationId
                          )
                        );
                      }}
                      className="padding-top-medium font-color-primary text-underline"
                    >
                      Can't reach ?
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.status,
  profile: state.profile,
  procedures: state.procedures,
  locations: state.locations
});

const mapDispatchToProps = function () {
  return {
    setSelectedProcedure: (currentProcedure) =>
      setSelectedProcedure(currentProcedure),
    completeProcedure: (data) => completeProcedure(data),
    getProceduresHistory: (procedureId, locationId) =>
      getProceduresHistory(procedureId, locationId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedProcedure);

const getValidTimingData = (period, value) => {
  switch (period) {
    case "hours":
      return value * 60 * 60 * 1000;
    case "days":
      return value * 24 * 60 * 60 * 1000;
    case "months":
      return value * 30 * 24 * 60 * 60 * 1000;
    default:
      return 0;
  }
};

function getTimestampsBetween(startingTime, endingTime, interval) {
  const timestamps = [];
  let currentTime = startingTime;
  while (currentTime <= endingTime) {
    timestamps.push(currentTime);
    currentTime += interval;
  }
  return timestamps;
}
