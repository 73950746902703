import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Button from "./Components/Button/Button";
import Header from "./Components/Header/Header";
import SnackBar from "./Components/SnackBar/SnackBar";
import { forceRefreshAccessToken } from "./Redux/authentication/actions";
import {
  addUserDistance,
  getLocations,
  setUserLocation
} from "./Redux/locations/action";
import { getProcedures, setSelectedProcedure } from "./Redux/procedures/action";
import Complaints from "./Screens/Complaints/Complaints";
import FaceIdentification from "./Screens/FaceIdentification/FaceIdentification";
import Home from "./Screens/Home/Home";
import LocationSearch from "./Screens/LocationSearch/LocationSearch";
import Login from "./Screens/Login/Login";
import Procedures from "./Screens/Procedures/Procedures";
import Profile from "./Screens/Profile/Profile";
import SelectedComplaint from "./Screens/SelectedComplaint/SelectedComplaint";
import SelectedProcedure from "./Screens/SelectedProcedure/SelectedProcedure";
import ViewUpdates from "./Screens/ViewUpdates/ViewUpdates";
import { useAuthSubscriber } from "./Services/authentication";
import { useProfileListener } from "./Services/database";
import useLiveLocation from "./Services/getLocation";
import { isValidArray, isValidObject } from "./Services/validators";
import { logRocketInit } from "./Utils/constants";
import { useComplaints } from "./Services/useComplaint";
// import UnsupportedDevice from "./Components/UnsupportedDevice/UnsupportedDevice";

function App(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [locationAccess, setLocationAccess] = useState(null);
  const [cameraAccess, setCameraAccess] = useState(null);

  const currentLocation = useLiveLocation(isAuth, locationAccess === "granted");

  useEffect(() => {
    const currentLocationFromStore = props.locations?.currentLocation;
    if (
      currentLocationFromStore === null ||
      (currentLocation?.latitude &&
        currentLocationFromStore?.latitude !== currentLocation?.latitude) ||
      (currentLocation?.longitude &&
        currentLocationFromStore?.longitude !== currentLocation?.longitude)
    ) {
      if (currentLocation?.latitude && currentLocation?.longitude)
        props.setUserLocation({
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude
        });
    }
    // console.log(currentLocation, "currentLocation");
    // eslint-disable-next-line
  }, [currentLocation]);

  useEffect(() => {
    if (props.locations.selectedLocation) {
      addUserDistance();
    }
    // eslint-disable-next-line
  }, [currentLocation, props.locations.selectedLocation]);

  useAuthSubscriber((isAuth) => {
    setIsAuth(isAuth);
  }, isAuth);

  useProfileListener({
    isAuth: isAuth,
    phoneNumber: props.auth.data.phoneNumber
  });

  const [getComplaintsNextPage, complaintsLoading] = useComplaints({
    filterData: props.complaints?.filter,
    isAuth: isAuth,
    locationData: props.locations.data,
    phoneNumber: props.auth?.data?.phoneNumber,
    searchKey: props.complaints?.searchKey,
    sortBy: props.complaints?.sortBy
  });

  useEffect(() => {
    const connectionData = props.profile?.data?.connected;
    if (isAuth) {
      props.getLocations(connectionData?.location);
      if (isValidArray(connectionData?.procedure)) {
        props.getProcedures(connectionData?.procedure);
      }
    }
    // eslint-disable-next-line
  }, [props.profile?.data?.connected, isAuth]);

  useEffect(() => {
    if (props.profile?.data?.accessSyncStatus === false && isAuth) {
      props.forceRefreshAccessToken(true);
    }
    // eslint-disable-next-line
  }, [props.profile?.data, isAuth]);

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logRocketInit.key) {
        LogRocket.init(logRocketInit.key, release);
      }
    }
  }, []);

  const handlePermissionChange = (setAccess) => (event) => {
    setAccess(event.target.state);
  };

  const queryPermission = (name, setAccess) => {
    if (navigator.permissions) {
      navigator.permissions.query({ name }).then((result) => {
        setAccess(result.state);
        const handleChange = handlePermissionChange(setAccess);
        result.addEventListener("change", handleChange);

        return () => {
          result.removeEventListener("change", handleChange);
        };
      });
    }
  };

  useEffect(() => {
    const cleanupCamera = queryPermission("camera", setCameraAccess);
    const cleanupLocation = queryPermission("geolocation", setLocationAccess);

    return () => {
      if (cleanupCamera) {
        cleanupCamera();
      }
      if (cleanupLocation) {
        cleanupLocation();
      }
    };
    // eslint-disable-next-line
  }, []);

  const requestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocationAccess("granted");
      },
      (error) => {
        console.error("Location permission denied or failed", error);
        setLocationAccess("denied");
      }
    );
  };

  const requestCameraPermission = () => {
    // wil not work on local host
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setCameraAccess("granted");
      })
      .catch((err) => {
        console.error("Camera permission denied or failed", err);
        setCameraAccess("denied");
      });
  };

  return (
    // process.env.REACT_APP_STAGING === "production" &&
    // window.matchMedia("(display-mode: standalone)").matches) ||
    // process.env.REACT_APP_STAGING !== "production" ? (
    <div className="inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center">
      <div
        style={{
          maxWidth: "767px"
        }}
        className="inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center"
      >
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <Login navigate={navigate} />
              </PublicRoute>
            }
          />
          <Route
            path="/:locationId/procedures"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                {locationAccess !== "granted" && !window.Cypress ? (
                  <PermissionDeniedFallback
                    fallBackImage={<LocationFallBack />}
                    title={
                      locationAccess === "prompt"
                        ? "Allow Location Permission"
                        : "Location Permission Denied"
                    }
                    description={
                      locationAccess === "prompt"
                        ? "Enables us to monitor your real-time location"
                        : "You have blocked from tracking your location. To use this, change your location settings in browser."
                    }
                    showButton={locationAccess === "prompt"}
                    onEnableClick={() => {
                      requestLocationPermission();
                    }}
                  />
                ) : (
                  <Procedures navigate={navigate} />
                )}
              </ProtectedRoute>
            }
          />

          <Route
            path="/faceIdentification/locationSearch"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <LocationSearch
                  navigate={navigate}
                  locations={
                    isValidObject(props.locations.data)
                      ? Object.values(props.locations.data)
                      : []
                  }
                  loading={props.locations.loading}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/locationSearch"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <LocationSearch
                  navigate={navigate}
                  locations={
                    isValidObject(props.locations.data)
                      ? Object.values(props.locations.data)?.filter(
                          (data) => data.access === "public"
                        )
                      : []
                  }
                  loading={props.locations.loading}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path="/procedures/:procedureId"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                {locationAccess !== "granted" && !window.Cypress ? (
                  <PermissionDeniedFallback
                    fallBackImage={<LocationFallBack />}
                    title={
                      locationAccess === "prompt"
                        ? "Allow Location Permission"
                        : "Location Permission Denied"
                    }
                    description={
                      locationAccess === "prompt"
                        ? "Enables us to monitor your real-time location"
                        : "You have blocked from tracking your location. To use this, change your location settings in browser."
                    }
                    showButton={locationAccess === "prompt"}
                    onEnableClick={() => {
                      requestLocationPermission();
                    }}
                  />
                ) : (
                  <SelectedProcedure navigate={navigate} />
                )}
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <Home navigate={navigate} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/complaints"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <Complaints
                  navigate={navigate}
                  getNextSetOfComplaints={() => {
                    getComplaintsNextPage();
                  }}
                  paginationLoading={complaintsLoading.pagination}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/complaints/:complaintId"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <SelectedComplaint navigate={navigate} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/viewUpdates/:complaintId"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <ViewUpdates navigate={navigate} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                <Profile navigate={navigate} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/faceIdentification/*"
            element={
              <ProtectedRoute
                uid={props.auth.data.uid}
                profileData={props.profile.data}
              >
                {locationAccess !== "granted" && !window.Cypress ? (
                  <PermissionDeniedFallback
                    navigate={navigate}
                    fallBackImage={<LocationFallBack />}
                    title={
                      locationAccess === "prompt"
                        ? "Allow Location Permission"
                        : "Location Permission Denied"
                    }
                    description={
                      locationAccess === "prompt"
                        ? "Enables us to monitor your real-time location"
                        : "You have blocked from tracking your location. To use this, change your location settings in browser."
                    }
                    showButton={locationAccess === "prompt"}
                    onEnableClick={() => {
                      requestLocationPermission();
                    }}
                  />
                ) : cameraAccess !== "granted" && !window.Cypress ? (
                  <PermissionDeniedFallback
                    navigate={navigate}
                    fallBackImage={<CameraFallBack />}
                    title={
                      cameraAccess === "prompt"
                        ? "Allow Camera Permission"
                        : "Camera Permission Denied"
                    }
                    description={
                      cameraAccess === "prompt"
                        ? "Enables us to take photo for attendance"
                        : "You have blocked from camera permission. To use this, change your camera settings in browser."
                    }
                    showButton={cameraAccess === "prompt"}
                    onEnableClick={() => {
                      requestCameraPermission();
                    }}
                  />
                ) : (
                  <FaceIdentification navigate={navigate} />
                )}
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <SnackBar
          message={status}
          status={props.status}
          type={props.status.code === null ? "success" : "error"}
        />
      </div>
    </div>
  );
  // : (
  //   <UnsupportedDevice appName="doctors" />
  // );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    complaints: state.complaints,
    locations: state.locations,
    procedures: state.procedures,
    profile: state.profile,
    status: state.status
  };
};

const mapDispatchToProps = function () {
  return {
    setUserLocation: (currentLocation) => setUserLocation(currentLocation),
    setSelectedProcedure: (data) => setSelectedProcedure(data),
    forceRefreshAccessToken: (status) => forceRefreshAccessToken(status),
    getLocations: (locationIds) => getLocations(locationIds),
    getProcedures: (procedureIds) => getProcedures(procedureIds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const PermissionDeniedFallback = (props) => {
  // for iphone only
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    props.onEnableClick();
  }
  return (
    <div className="inherit-parent-width inherit-parent-height">
      <Header
        title={
          window.location.pathname.split("/")?.[
            window.location.pathname.split("/").length - 1
          ]
        }
        backOnClick={() => {
          props.navigate(-1);
        }}
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />

      <div
        className="remaining-body-height padding-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
        data-cy="location-permission-denied-fallback"
      >
        <div className="inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
          <div>{props.fallBackImage}</div>
          <div className=" padding-top-larger font-family-RHD-medium">
            {props.title}
          </div>
          <div className=" padding-top-medium text-align-center font-size-medium">
            {props.description}
          </div>
        </div>
        {props.showButton && (
          <div className="inherit-parent-width">
            <Button
              text="Enable"
              disabled={/iPhone|iPad|iPod/i.test(navigator.userAgent)}
              onClick={props.onEnableClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const LocationFallBack = () => {
  return (
    <svg width="94" height="96" viewBox="0 0 94 96" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7445 51.7583C52.6989 51.7583 55.9046 48.457 55.9046 44.3847C55.9046 40.3123 52.6989 37.0111 48.7445 37.0111C44.79 37.0111 41.5843 40.3123 41.5843 44.3847C41.5843 48.457 44.79 51.7583 48.7445 51.7583ZM48.7445 54.2162C54.017 54.2162 58.2913 49.8145 58.2913 44.3847C58.2913 38.9549 54.017 34.5532 48.7445 34.5532C43.4719 34.5532 39.1976 38.9549 39.1976 44.3847C39.1976 49.8145 43.4719 54.2162 48.7445 54.2162Z"
        fill="#C2C2C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9556 92.006C28.0901 92.2723 29.6528 92.5222 31.5761 92.7396C35.9478 93.2337 42.0183 93.5421 48.7445 93.5421C55.4706 93.5421 61.5411 93.2337 65.9128 92.7396C67.8361 92.5222 69.3988 92.2723 70.5333 92.006C69.3988 91.7396 67.8361 91.4897 65.9128 91.2723C61.5411 90.7782 55.4706 90.4698 48.7445 90.4698C42.0183 90.4698 35.9478 90.7782 31.5761 91.2723C29.6528 91.4897 28.0901 91.7396 26.9556 92.006ZM31.3157 88.8291C35.7981 88.3225 41.9596 88.0119 48.7445 88.0119C55.5293 88.0119 61.6908 88.3225 66.1732 88.8291C68.4067 89.0816 70.2647 89.3871 71.5856 89.7401C72.2365 89.9141 72.8248 90.1168 73.2754 90.3657C73.4994 90.4894 73.7499 90.6571 73.9579 90.8886C74.1715 91.1263 74.4017 91.5046 74.4017 92.006C74.4017 92.5073 74.1715 92.8857 73.9579 93.1233C73.7499 93.3548 73.4994 93.5225 73.2754 93.6462C72.8248 93.8951 72.2365 94.0979 71.5856 94.2718C70.2647 94.6248 68.4067 94.9303 66.1732 95.1828C61.6908 95.6894 55.5293 96 48.7445 96C41.9596 96 35.7981 95.6894 31.3157 95.1828C29.0822 94.9303 27.2242 94.6248 25.9033 94.2718C25.2524 94.0979 24.6641 93.8951 24.2135 93.6462C23.9895 93.5225 23.739 93.3548 23.531 93.1233C23.3174 92.8857 23.0872 92.5073 23.0872 92.006C23.0872 91.5046 23.3174 91.1263 23.531 90.8886C23.739 90.6571 23.9895 90.4894 24.2135 90.3657C24.6641 90.1168 25.2524 89.9141 25.9033 89.7401C27.2242 89.3871 29.0822 89.0816 31.3157 88.8291Z"
        fill="#C2C2C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9217 18.6408C53.3444 14.4762 43.9522 14.244 36.7764 18.8617C25.9408 25.8347 21.9806 37.5834 23.8208 46.9053C25.0404 53.0838 27.9384 58.1195 32.3276 63.278C36.058 67.6622 40.8099 72.075 46.4769 77.3375C47.2558 78.0609 48.0521 78.8003 48.8653 79.5579C50.4686 77.9137 51.9899 76.3732 53.4359 74.9088C60.6994 67.5533 66.0632 62.1216 70.3756 55.1476C73.0856 50.7649 74.7225 45.7327 74.6076 40.6342C74.4978 35.7593 73.3431 31.5272 71.1122 27.8987C68.8796 24.2673 65.527 21.1721 60.9217 18.6408ZM35.5116 16.7774C43.5165 11.6261 53.8305 11.9573 62.0455 16.4725C66.944 19.1648 70.6358 22.5291 73.1287 26.5839C75.6234 30.6415 76.8753 35.3222 76.9937 40.5772C77.1216 46.2542 75.3006 51.7589 72.3888 56.4678C67.9174 63.6988 62.3184 69.3664 55.0328 76.741C53.3624 78.4319 51.6034 80.2124 49.749 82.1221L48.9454 82.9497L48.1035 82.1635C47.0111 81.1433 45.9436 80.1522 44.9027 79.1857C39.2409 73.9288 34.3639 69.4006 30.5325 64.8979C25.9673 59.5326 22.8095 54.1203 21.4819 47.3949C19.4382 37.0417 23.8542 24.2791 35.5116 16.7774Z"
        fill="#C2C2C2"
      />
      <circle opacity="0.5" cx="17.5" cy="75.5" r="1.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="59.5" cy="1" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="20" cy="5.5" r="1.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="3" cy="31.5" r="2.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="82.5" cy="76" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="84.5" cy="21" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="1.5" cy="57" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="91.5" cy="51" r="2" fill="#C2C2C2" />
    </svg>
  );
};

const CameraFallBack = () => {
  return (
    <svg width="94" height="77" viewBox="0 0 94 77" fill="none">
      <circle opacity="0.5" cx="17.5" cy="75.5" r="1.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="59.5" cy="1" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="20" cy="5.5" r="1.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="3" cy="31.5" r="2.5" fill="#C2C2C2" />
      <circle opacity="0.5" cx="82.5" cy="76" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="84.5" cy="21" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="1.5" cy="57" r="1" fill="#C2C2C2" />
      <circle opacity="0.5" cx="91.5" cy="51" r="2" fill="#C2C2C2" />
      <path
        d="M47.0219 51.2028C52.145 51.2028 56.2732 46.9301 56.2732 41.6906C56.2732 36.4511 52.145 32.1784 47.0219 32.1784C41.8988 32.1784 37.7706 36.4511 37.7706 41.6906C37.7706 46.9301 41.8988 51.2028 47.0219 51.2028ZM58.1903 41.6906C58.1903 48.0648 53.1763 53.2068 47.0219 53.2068C40.8675 53.2068 35.8535 48.0648 35.8535 41.6906C35.8535 35.3164 40.8675 30.1743 47.0219 30.1743C53.1763 30.1743 58.1903 35.3164 58.1903 41.6906Z"
        fill="#C2C2C2"
        stroke="#C2C2C2"
      />
      <path
        d="M18 61.8V24.3111C18 24.2007 18.0895 24.1111 18.2 24.1111H32.5534C32.6237 24.1111 32.6888 24.0743 32.7249 24.014L36.2751 18.0971C36.3112 18.0369 36.3763 18 36.4466 18H55.7928C55.8535 18 55.911 18.0276 55.9489 18.0751L60.7177 24.036C60.7557 24.0835 60.8132 24.1111 60.8739 24.1111H75.2445C75.3549 24.1111 75.4445 24.2007 75.4445 24.3111V61.8C75.4445 61.9105 75.3549 62 75.2445 62H18.2C18.0895 62 18 61.9105 18 61.8Z"
        stroke="#C2C2C2"
        strokeWidth="2.5"
      />
    </svg>
  );
};
